import GUI from 'lil-gui';

class HelperGUI {
  gui;

  constructor() {
    this.gui = new GUI();
    this.gui.close();
  }

  addHemiLight(hemiLight) {
    const hemiLightFolder = this.gui.addFolder('Hemisphere Light');
    hemiLightFolder.add(hemiLight, 'intensity', 0, 5, 0.1);
  }

  addLeftLight(leftLight) {
    const leftLightFolder = this.gui.addFolder('Left Light');

    leftLightFolder.add(leftLight, 'intensity', 0, 5, 0.1);
    leftLightFolder.add(leftLight.position, 'x', -5, 0, 0.05).name('positionX');
    leftLightFolder.add(leftLight.position, 'y', 1, 5, 0.05).name('positionY');
    leftLightFolder.add(leftLight.position, 'z', -2, 8, 0.05).name('positionZ');
    leftLightFolder
      .add(leftLight.target.position, 'x', -3, 3, 0.1)
      .name('targetX');
    leftLightFolder
      .add(leftLight.target.position, 'y', -2, 2, 0.1)
      .name('targetY');
    leftLightFolder
      .add(leftLight.target.position, 'z', -6, 4, 0.1)
      .name('targetZ');
  }

  addRightLight(rightLight) {
    const rightLightFolder = this.gui.addFolder('Right Light');

    rightLightFolder.add(rightLight, 'intensity', 0, 5, 0.1);
    rightLightFolder
      .add(rightLight.position, 'x', 0, 5, 0.05)
      .name('positionX');
    rightLightFolder
      .add(rightLight.position, 'y', 1, 5, 0.05)
      .name('positionY');
    rightLightFolder
      .add(rightLight.position, 'z', -2, 8, 0.05)
      .name('positionZ');
    rightLightFolder
      .add(rightLight.target.position, 'x', -3, 3, 0.1)
      .name('targetX');
    rightLightFolder
      .add(rightLight.target.position, 'y', -2, 2, 0.1)
      .name('targetY');
    rightLightFolder
      .add(rightLight.target.position, 'z', -6, 4, 0.1)
      .name('targetZ');
  }
}

export { HelperGUI };

import { Group } from 'three';

import { Knobs } from './Knobs.js';
import { SSRangeTop } from './SSRangeTop.js';

class Sully2200 {
  // All range meshes should be added to (or removed from) this group
  range = new Group();
  #classiqueTrim = new Group();
  #moderneTrim = new Group();
  #baseBurners = new Group();
  #sp1Option = new Group();
  #sp2Option = new Group();
  #sp3Option = new Group();
  #sp4Option = new Group();

  // 3D model and texture assets
  #assets;

  // Knobs placement
  #knobs;

  #rangeTopOptions;

  #ssTop;

  #materials;

  #state = {
    line: 'classique',
    top: 'classique',
    trim: 'brass',
    color: 'burgundyRed',
    sp1: 'SS',
    sp2: 'LAG024UR',
    sp3: 'SS',
    sp4: 'SS',
  };

  constructor(assets, materials, rangeTopOptions, knobModels) {
    this.#assets = assets;
    this.#materials = materials;
    this.#rangeTopOptions = rangeTopOptions;
    this.#classiqueTrim.name = 'classique-trim';
    this.#loadModels();
    this.#applyTextures();
    this.#assembleModerneTrim();
    this.#knobs = new Knobs(knobModels, this.range, this.#materials);
    this.#ssTop = new SSRangeTop(this.range, rangeTopOptions);
    this.range.add(
      this.#baseBurners,
      this.#sp1Option,
      this.#sp2Option,
      this.#sp3Option,
      this.#sp4Option
    );

    // Configure optional burners for initial display
    this.#refreshRangeTop();

    this.range.getObjectByName('2200_Range_Top_Rim').visible = false;

    const top = this.#ssTop.assembleSSRangeTop({
      fourFeux: false,
      sp1: this.#state.sp1,
      sp2: this.#state.sp2,
      sp3: this.#state.sp3,
      sp4: this.#state.sp4,
    });
    top.name = 'range-top';
    this.range.add(top);
  }

  changeLine(line) {
    this.#state.line = line;
    this.#knobs.changeLine(line);
    this.#changeControlPanelAndSide(line);

    if (line === 'moderne') {
      this.range.remove(this.#classiqueTrim);
      this.range.add(this.#moderneTrim);

      if (!['brushedSS', 'chrome'].includes(this.#state.trim)) {
        this.changeTrim('chrome');
      }
    } else if (line === 'classique') {
      this.range.remove(this.#moderneTrim);
      this.range.add(this.#classiqueTrim);
    }
  }

  changeRangeTop(rangeTop, assembleTop = true) {
    this.#state.top = rangeTop;
    this.#baseBurners.clear();

    switch (rangeTop) {
      case 'classique': {
        this.#add18KBurner(0, this.#baseBurners);
        this.#add5And11KBurners(0, this.#baseBurners);
        this.#knobs.threeFeuxBaseTop();
        break;
      }

      case 'traditional': {
        this.#addTradPlate(0, this.#baseBurners);
        this.#add5And11KBurners(0, this.#baseBurners);
        this.#knobs.threeFeuxBaseTop();
        break;
      }

      case '4-feux':
        this.#add11KBurners(-1.051, this.#baseBurners);
        this.#add5And15KBurners(0, this.#baseBurners);
        this.#knobs.fourFeuxBaseTop();
        break;
    }

    if (assembleTop) {
      const oldRangeTop = this.range.getObjectByName('range-top');
      this.range.remove(oldRangeTop);

      const newRangeTop = this.#ssTop.assembleSSRangeTop({
        fourFeux: rangeTop === '4-feux',
      });
      newRangeTop.name = 'range-top';
      this.range.add(newRangeTop);

      this.#refreshRangeTop();
    }
  }

  changeTrim(trim) {
    this.#state.trim = trim;
    this.#materials.changeTrim(trim, this.#trimParts());
    this.#knobs.changeTrim(trim);
  }

  changeColor(color) {
    this.#state.color = color;
    this.#materials.changeColor(color, this.#colorParts());
    this.#knobs.changeKnobDialColor(color);

    if (this.#state.line !== 'moderne') {
      this.#materials.changeColor(
        color,
        [this.range.getObjectByName('FRONT_KNOB_PLATE')] // control panel
      );
    }
  }

  changeLeftCupboard(type) {
    switch (type) {
      case 'warming':
        this.#knobs.selectWarmingCupboard('far-left');
        break;

      case 'storage':
        this.#knobs.selectStorageCupboard('far-left');
        break;
    }
  }

  changeSp1(option, assembleTop = true) {
    this.#state.sp1 = option;
    this.#sp1Option.clear();

    switch (option) {
      case 'LAG020UR': // 2 - 11k burners
        this.#add11KBurners(-1.795, this.#sp1Option);
        this.#knobs.add2Burners('far-left');
        break;

      case 'LAG024UR': // 2 - 15k burners
        this.#add15KBurners(-1.795, this.#sp1Option);
        this.#knobs.add2Burners('far-left');
        break;

      case 'LAG010UR': // 1 - 18k burners
        this.#add18KBurner(-0.7, this.#sp1Option);
        this.#knobs.add1Burner('far-left');
        break;

      case 'LAG010CK': // Flame Grill
        this.#addFlameGrill(-1.795, this.#sp1Option);
        this.#knobs.addFlameGrill('far-left');
        break;

      case 'LAE010TPK': // Electric Plancha
        this.#addPlancha(-1.795, this.#sp1Option);
        this.#knobs.addPlancha('far-left');
        break;

      case 'LAG010CF': // Traditional Simmer Plate
        this.#addTradPlate(-0.7, this.#sp1Option);
        this.#knobs.add1Burner('far-left');
        break;

      case 'LAE010CVA': // Multi-Cooker
        this.#addMultiCooker(-1.795, this.#sp1Option);
        this.#knobs.addMultiCooker('far-left');
        break;

      case 'LAE020I': // 2 Induction Rings
        this.#addInductionRings(-1.795, this.#sp1Option);
        this.#knobs.addInductionRings('far-left');
        break;

      case 'SS': // Stainless Steel Worktop
        this.#knobs.addSSWorkstation('far-left');
        break;

      default:
        this.#knobs.addSSWorkstation('far-left');
        break;
    }

    if (assembleTop) {
      const oldRangeTop = this.range.getObjectByName('range-top');
      this.range.remove(oldRangeTop);

      const newRangeTop = this.#ssTop.assembleSSRangeTop({ sp1: option });
      newRangeTop.name = 'range-top';
      this.range.add(newRangeTop);
    }
  }

  changeSp2(option, assembleTop = true) {
    this.#state.sp2 = option;
    this.#sp2Option.clear();

    const adjust = this.#state.top === '4-feux' ? 0.056 : 0;

    switch (option) {
      case 'LAG020UR': // 2 - 11k burners
        this.#add11KBurners(-1.445 + adjust, this.#sp2Option);
        this.#knobs.add2Burners('left');
        break;

      case 'LAG024UR': // 2 - 15k burners
        this.#add15KBurners(-1.445 + adjust, this.#sp2Option);
        this.#knobs.add2Burners('left');
        break;

      case 'LAG010UR': // 1 - 18k burners
        this.#add18KBurner(-0.297, this.#sp2Option);
        this.#knobs.add1Burner('left');
        break;

      case 'LAE010TPK': // Electric Plancha
        this.#addPlancha(-1.445 + adjust, this.#sp2Option);
        this.#knobs.addPlancha('left');
        break;

      case 'LAE020I': // 2 Induction Rings
        this.#addInductionRings(-1.445 + adjust, this.#sp2Option);
        this.#knobs.addInductionRings('far-left');
        break;

      case 'SS': // Stainless Steel Worktop
        this.#knobs.addSSWorkstation('left');
        break;

      default:
        this.#knobs.addSSWorkstation('left');
        break;
    }

    if (assembleTop) {
      const oldRangeTop = this.range.getObjectByName('range-top');
      this.range.remove(oldRangeTop);

      const newRangeTop = this.#ssTop.assembleSSRangeTop({ sp2: option });
      newRangeTop.name = 'range-top';
      this.range.add(newRangeTop);
    }
  }

  changeSp3(option, assembleTop = true) {
    this.#state.sp3 = option;
    this.#sp3Option.clear();

    switch (option) {
      case 'LAG020UR': // 2 - 11k burners
        this.#add11KBurners(-0.403, this.#sp3Option);
        this.#knobs.add2Burners('right');
        break;

      case 'LAG024UR': // 2 - 15k burners
        this.#add15KBurners(-0.403, this.#sp3Option);
        this.#knobs.add2Burners('right');
        break;

      case 'LAG010UR': // 1 - 18k burners
        this.#add18KBurner(0.69, this.#sp3Option);
        this.#knobs.add1Burner('right');
        break;

      case 'LAE010TPK': // Electric Plancha
        this.#addPlancha(-0.403, this.#sp3Option);
        this.#knobs.addPlancha('right');
        break;

      case 'LAG010CF': // Traditional Simmer Plate
        this.#addTradPlate(0.69, this.#sp3Option);
        this.#knobs.add1Burner('right');
        break;

      case 'LAE020I': // 2 Induction Rings
        this.#addInductionRings(-0.403, this.#sp3Option);
        this.#knobs.addInductionRings('right');
        break;

      case 'SS': // Stainless Steel Worktop
        this.#knobs.addSSWorkstation('right');
        break;

      default:
        this.#knobs.addSSWorkstation('right');
        break;
    }

    if (assembleTop) {
      const oldRangeTop = this.range.getObjectByName('range-top');
      this.range.remove(oldRangeTop);

      const newRangeTop = this.#ssTop.assembleSSRangeTop({ sp3: option });
      newRangeTop.name = 'range-top';
      this.range.add(newRangeTop);
    }
  }

  changeSp4(option, assembleTop = true) {
    this.#state.sp4 = option;
    this.#sp4Option.clear();

    switch (option) {
      case 'LAG020UR': // 2 - 11k burners
        this.#add11KBurners(0, this.#sp4Option);
        this.#knobs.add2Burners('far-right');
        break;

      case 'LAG024UR': // 2 - 15k burners
        this.#add15KBurners(0, this.#sp4Option);
        this.#knobs.add2Burners('far-right');
        break;

      case 'LAG010UR': // 1 - 18k burners
        this.#add18KBurner(1.094, this.#sp4Option);
        this.#knobs.add1Burner('far-right');
        break;

      case 'LAG010CK': // Flame Grill
        this.#addFlameGrill(0, this.#sp4Option);
        this.#knobs.addFlameGrill('far-right');
        break;

      case 'LAE010TPK': // Electric Plancha
        this.#addPlancha(0, this.#sp4Option);
        this.#knobs.addPlancha('far-right');
        break;

      case 'LAG010CF': // Traditional Simmer Plate
        this.#addTradPlate(1.094, this.#sp4Option);
        this.#knobs.add1Burner('far-right');
        break;

      case 'LAE010CVA': // Multi-Cooker
        this.#addMultiCooker(0, this.#sp4Option);
        this.#knobs.addMultiCooker('far-right');
        break;

      case 'LAE020I': // 2 Induction Rings
        this.#addInductionRings(0, this.#sp4Option);
        this.#knobs.addInductionRings('far-right');
        break;

      case 'SS': // Stainless Steel Worktop
        this.#knobs.addSSWorkstation('far-right');
        break;

      default:
        this.#knobs.addSSWorkstation('far-right');
        break;
    }

    if (assembleTop) {
      const oldRangeTop = this.range.getObjectByName('range-top');
      this.range.remove(oldRangeTop);

      const newRangeTop = this.#ssTop.assembleSSRangeTop({ sp4: option });
      newRangeTop.name = 'range-top';
      this.range.add(newRangeTop);
    }
  }

  changeRightCupboard(type) {
    switch (type) {
      case 'warming':
        this.#knobs.selectWarmingCupboard('far-right');
        break;

      case 'storage':
        this.#knobs.selectStorageCupboard('far-right');
        break;
    }
  }

  #refreshRangeTop() {
    this.changeSp1(this.#state.sp1, false);
    this.changeSp2(this.#state.sp2, false);
    this.changeSp3(this.#state.sp3, false);
    this.changeSp4(this.#state.sp4, false);
  }

  #loadModels() {
    // All meshes from the main Sully 2200 model
    this.#assets.rangeData.scene.children.forEach((child) => {
      if (this.#classiqueMeshNames().includes(child.name)) {
        this.#classiqueTrim.add(child.clone());
      } else {
        this.range.add(child.clone());
      }
    });

    this.#assets.frontGrillData.scene.children.forEach((child) => {
      if (child.name === 'M_FRONT_EXHAUST') {
        this.#moderneTrim.add(child.clone());
      } else {
        this.#classiqueTrim.add(child.clone());
      }
    });

    this.range.add(this.#classiqueTrim);

    // Moderne trim models
    this.#assets.moderneTrimData.scene.children.forEach((child) => {
      this.#moderneTrim.add(child.clone());
    });

    // TODO: Remove this mesh from the range model
    this.range.getObjectByName('C_FRONT_EXHAUST').visible = false;
  }

  #applyTextures() {
    this.#materials.applyStainlessSteelTexture(...this.#stainlessSteelParts());

    this.#materials.applyGalvanizedSteelTexture(
      ...this.#galvanizedSteelParts()
    );
  }

  #assembleModerneTrim() {
    const rightOvenBar = this.#moderneTrim
      .getObjectByName('M_Rods_25x684')
      .clone();
    rightOvenBar.position.x += 0.702;

    const rightCupboardBar = this.#moderneTrim
      .getObjectByName('M_Rods_25x382')
      .clone();
    rightCupboardBar.position.x += 1.802;

    const lCupBarRightSupport = this.#moderneTrim
      .getObjectByName('M_Rod_Support_17x25')
      .clone();
    lCupBarRightSupport.position.x += 0.24;

    const lOvenBarLeftSupport = lCupBarRightSupport.clone();
    lOvenBarLeftSupport.position.x += 0.16;

    const lOvenBarRightSupport = lOvenBarLeftSupport.clone();
    lOvenBarRightSupport.position.x += 0.53;

    const rOvenBarLeftSupport = lOvenBarRightSupport.clone();
    rOvenBarLeftSupport.position.x += 0.18;

    const rOvenBarRightSupport = rOvenBarLeftSupport.clone();
    rOvenBarRightSupport.position.x += 0.53;

    const rCupBarLeftSupport = rOvenBarRightSupport.clone();
    rCupBarLeftSupport.position.x += 0.16;

    const rCupBarRightSupport = rCupBarLeftSupport.clone();
    rCupBarRightSupport.position.x += 0.24;

    const rightDrawerLip = this.#moderneTrim
      .getObjectByName('M_Lip_684')
      .clone();
    rightDrawerLip.position.x += 0.702;

    this.#moderneTrim.add(
      rightOvenBar,
      rightCupboardBar,
      lCupBarRightSupport,
      lOvenBarLeftSupport,
      lOvenBarRightSupport,
      rOvenBarLeftSupport,
      rOvenBarRightSupport,
      rCupBarLeftSupport,
      rCupBarRightSupport,
      rightDrawerLip
    );
  }

  #add5And11KBurners(xPos, group) {
    const fiveAnd11KBurners = this.#rangeTopOptions.fiveAnd11KBurners.clone();
    fiveAnd11KBurners.position.x += xPos;

    group.add(fiveAnd11KBurners);
  }

  #add5And15KBurners(xPos, group) {
    const fiveAnd15KBurners = this.#rangeTopOptions.fiveAnd15KBurners.clone();
    fiveAnd15KBurners.position.x += xPos;

    group.add(fiveAnd15KBurners);
  }

  #add11KBurners(xPos, group) {
    const elevenKBurners = this.#rangeTopOptions.elevenKBurners.clone();
    elevenKBurners.position.x += xPos + 0.74;

    group.add(elevenKBurners);
  }

  #add15KBurners(xPos, group) {
    const fifteenKBurners = this.#rangeTopOptions.fifteenKBurners.clone();
    fifteenKBurners.position.x += xPos + 0.74;

    group.add(fifteenKBurners);
  }

  #add18KBurner(xPos, group) {
    const eighteenKBurner = this.#rangeTopOptions.eighteenKBurner.clone();
    eighteenKBurner.position.x += xPos;

    group.add(eighteenKBurner);
  }

  #addTradPlate(xPos, group) {
    const tradPlateGroup = this.#rangeTopOptions.tradPlateGroup.clone();
    tradPlateGroup.position.x += xPos;

    group.add(tradPlateGroup);
  }

  #addMultiCooker(xPos, group) {
    const multiCooker = this.#rangeTopOptions.multiCooker.clone();
    multiCooker.position.x += xPos;

    group.add(multiCooker);
  }

  #addPlancha(xPos, group) {
    const plancha = this.#rangeTopOptions.plancha.clone();
    plancha.position.x += xPos;

    group.add(plancha);
  }

  #addFlameGrill(xPos, group) {
    const flameGrill = this.#rangeTopOptions.flameGrill.clone();
    flameGrill.position.x += xPos;

    group.add(flameGrill);
  }

  #addInductionRings(xPos, group) {
    const inductionRings = this.#rangeTopOptions.inductionRings.clone();
    inductionRings.position.x += xPos;

    group.add(inductionRings);
  }

  #changeControlPanelAndSide(line) {
    if (line === 'moderne') {
      this.#materials.applyStainlessSteelTexture(
        this.range.getObjectByName('FRONT_KNOB_PLATE'), // control panel
        this.range.getObjectByName('MAIN_BODY') // main body
      );
    } else if (line === 'classique') {
      this.#materials.changeColor(this.#state.color, [
        this.range.getObjectByName('FRONT_KNOB_PLATE'), // control panel
        this.range.getObjectByName('MAIN_BODY'), // main body
      ]);
    }
  }

  #trimParts() {
    return [
      this.#classiqueTrim.getObjectByName('C_TRIM002'), // far right towel bar support
      this.#classiqueTrim.getObjectByName('C_TRIM006'), // right cupboard right bar support
      this.#classiqueTrim.getObjectByName('C_TRIM008'), // right cupboard left bar support
      this.#classiqueTrim.getObjectByName('C_TRIM016'), // right oven right bar support
      this.#classiqueTrim.getObjectByName('C_TRIM009'), // left oven right bar support
      this.#classiqueTrim.getObjectByName('C_TRIM011'), // left cupboard right bar support
      this.#classiqueTrim.getObjectByName('C_TRIM013'), // right oven left bar support
      this.#classiqueTrim.getObjectByName('C_TRIM010'), // left oven left bar support
      this.#classiqueTrim.getObjectByName('C_TRIM012'), // left cupboard left bar support
      this.#classiqueTrim.getObjectByName('C_TRIM001'), // right drawer right pull
      this.#classiqueTrim.getObjectByName('C_TRIM014'), // right drawer left pull
      this.#classiqueTrim.getObjectByName('C_TRIM015'), // left drawer right pull
      this.#classiqueTrim.getObjectByName('C_TRIM'), // left drawer left pull
      this.#classiqueTrim.getObjectByName('C_TRIM007'), // far left towel bar support
      this.#classiqueTrim.getObjectByName('C_TRIM003'), // right towel bar support
      this.#classiqueTrim.getObjectByName('C_TRIM004'), // center towel bar support
      this.#classiqueTrim.getObjectByName('C_TRIM005'), // left towel bar support
    ];
  }

  #colorParts() {
    return [
      this.range.getObjectByName('MAIN_BODY'),
      this.range.getObjectByName('O_DOOR'), // right oven
      this.range.getObjectByName('O_DOOR001'), // right drawer
      this.range.getObjectByName('O_DOOR002'), // left cupboard
      this.range.getObjectByName('O_DOOR003'), // left oven
      this.range.getObjectByName('O_DOOR004'), // left drawer
      this.range.getObjectByName('O_DOOR005'), // right cupboard
    ];
  }

  #stainlessSteelParts() {
    return [
      this.range.getObjectByName('2200_Range_Top_Rim'),
      this.range.getObjectByName('Vented_Raised_Wall_Spacer'), // wall spacer
      this.range.getObjectByName('LO2_CHEMINEE'), // left cupboard vent
      this.range.getObjectByName('LO_CHEMINEE'), // left oven vent
      this.range.getObjectByName('RO2_CHEMINEE'), // right cupboard vent
      this.range.getObjectByName('RO_CHEMINEE'), // right oven vent
      this.range.getObjectByName('BOTTOM_PLATE'), // toe kick
      this.range.getObjectByName('BOTTOM_PLATE001'), // range base
      this.range.getObjectByName('C_FRONT_EXHAUST'), // front grill

      this.#classiqueTrim.getObjectByName('C_ROD002'), // right cupboard bar
      this.#classiqueTrim.getObjectByName('C_ROD'), // left cupboard bar
      this.#classiqueTrim.getObjectByName('C_ROD001'), // towel bar
      this.#classiqueTrim.getObjectByName('C_ROD003'), // right oven bar
      this.#classiqueTrim.getObjectByName('C_ROD004'), // left oven bar
      this.#classiqueTrim.getObjectByName('C_FRONT_EXHAUST'), // front grill

      this.#moderneTrim.getObjectByName('M_Lip_684'), // drawer bottom lip
      this.#moderneTrim.getObjectByName('M_Rods_25x684'), // left oven bar
      this.#moderneTrim.getObjectByName('M_Rods_25x382'), // left cupboard bar
      this.#moderneTrim.getObjectByName('M_Rod_Support_17x25'), // bar support
      this.#moderneTrim.getObjectByName('M_FRONT_EXHAUST'), // moderne "grill"
    ];
  }

  #galvanizedSteelParts() {
    return [
      this.range.getObjectByName('BODY_BACK'), // electric oven back
      this.range.getObjectByName('BODY_BACK001'), // body back
      this.range.getObjectByName('BODY_BACK002'), // gas oven back
      this.range.getObjectByName('BODY_BACK004'), // left cupboard back top
      this.range.getObjectByName('BODY_BACK005'), // right cupboard back bottom
      this.range.getObjectByName('BODY_BACK006'), // left cupboard back bottom
      this.range.getObjectByName('BODY_BACK007'), // right cupboard back top
      this.range.getObjectByName('BODY_BACK008'), // gas oven back electrical
      this.range.getObjectByName('C_H_P_L'), // left back bracket
      this.range.getObjectByName('C_H_P_R'), // right back bracket
    ];
  }

  #classiqueMeshNames() {
    return [
      'C_TRIM002',
      'C_TRIM006',
      'C_TRIM008',
      'C_TRIM016',
      'C_TRIM009',
      'C_TRIM011',
      'C_TRIM013',
      'C_TRIM010',
      'C_TRIM012',
      'C_TRIM001',
      'C_TRIM014',
      'C_TRIM015',
      'C_TRIM',
      'C_TRIM007',
      'C_TRIM003',
      'C_TRIM004',
      'C_TRIM005',
      'C_ROD002',
      'C_ROD',
      'C_ROD001',
      'C_ROD003',
      'C_ROD004',
    ];
  }
}

export { Sully2200 };

import { Mesh } from 'three';
import * as BufferGeometryUtils from 'three/addons/utils/BufferGeometryUtils.js';

class SSRangeTop {
  // Geometries
  #flameGrillPondGeo;
  #oneBurnerPondGeo;
  #spacerNarrowGeo;
  #spacerWideGeo;
  #sully4BurnerPondGeo;
  #topRimGeo;
  #twoBurnerPondNarrowGeo;
  #twoBurnerPondWideGeo;
  #workstationNarrowGeo;
  #workstationWideGeo;

  // Meshes
  #topRimMesh;

  // Configured range top options
  #state = {};

  constructor(range, rangeTopOptions) {
    const optionBases = rangeTopOptions.optionBases;

    this.#makeTopRimGeometry(range);
    this.#make1BurnerPondGeometry(optionBases);
    this.#makeWide2BurnerPondGeometry(optionBases);
    this.#makeNarrow2BurnerPondGeometry(optionBases);
    this.#makeWideWorkstationGeometry(optionBases);
    this.#makeNarrowWorkstationGeometry(optionBases);
    this.#makeFlameGrillPondGeometry(optionBases);
    this.#makeSully4BurnerPondGeometry(optionBases);
    this.#makeWideSpacerGeometry(optionBases);
    this.#makeNarrowSpacerGeometry(optionBases);
  }

  assembleSSRangeTop(newState) {
    this.#state = { ...this.#state, ...newState };

    const geometries = [
      this.#topRimGeo,
      this.#sp1Surface(),
      this.#sp1Sp2Spacer(),
      this.#sp2Surface(),
      this.#sp2BaseSpacer(),
      this.#leftBaseSurface(),
      this.#baseSp3Spacer(),
      this.#sp3Surface(),
      this.#sp3Sp4Spacer(),
      this.#sp4Surface(),
    ];
    if (!this.#state.fourFeux) {
      geometries.push(this.#midBaseSpacer(), this.#rightBaseSurface());
    }

    const mergedGeometry = BufferGeometryUtils.mergeGeometries(geometries);

    return new Mesh(mergedGeometry, this.#topRimMesh.material);
  }

  #sp1Surface() {
    const offset = 0;

    switch (this.#state.sp1) {
      case 'LAG020UR':
      case 'LAG024UR':
        return this.#wide2BurnerPond(offset);

      case 'LAG010UR':
      case 'LAG010CF':
      case 'LAE010CVA':
        return this.#oneBurnerPond(offset);

      case 'LAG010CK':
        return this.#flameGrillPond(offset);

      default:
        return this.#wideWorkstation(offset);
    }
  }

  #sp1Sp2Spacer() {
    const offset = 0.39;

    if (this.#state.fourFeux) {
      return this.#narrowSpacer(offset);
    } else {
      return this.#wideSpacer(offset);
    }
  }

  #sp2Surface() {
    let offset = 0.403;

    if (this.#state.fourFeux) {
      // More space to the left of the 4 feux burners
      switch (this.#state.sp2) {
        case 'LAG020UR':
        case 'LAG024UR':
          return this.#wide2BurnerPond(offset);

        case 'LAG010UR':
          return this.#oneBurnerPond(offset);

        default:
          return this.#wideWorkstation(offset);
      }
    } else {
      offset += 0.013;
      // Narrow space for optional burners
      switch (this.#state.sp2) {
        case 'LAG020UR':
        case 'LAG024UR':
          return this.#narrow2BurnerPond(offset);

        default:
          return this.#narrowWorkstation(offset);
      }
    }
  }

  #sp2BaseSpacer() {
    if (this.#state.fourFeux) {
      return this.#narrowSpacer(0.793);
    } else {
      return this.#wideSpacer(0.676);
    }
  }

  #leftBaseSurface() {
    if (this.#state.fourFeux) {
      return this.#sully4BurnerPond(0.806);
    } else {
      return this.#oneBurnerPond(0.702);
    }
  }

  #midBaseSpacer() {
    return this.#wideSpacer(1.092);
  }

  #rightBaseSurface() {
    return this.#narrow2BurnerPond(1.118);
  }

  #baseSp3Spacer() {
    return this.#narrowSpacer(1.378);
  }

  #sp3Surface() {
    const offset = 1.391;

    switch (this.#state.sp3) {
      case 'LAG020UR':
      case 'LAG024UR':
        return this.#wide2BurnerPond(offset);

      case 'LAG010UR':
      case 'LAG010CF':
        return this.#oneBurnerPond(offset);

      default:
        return this.#wideWorkstation(offset);
    }
  }

  #sp3Sp4Spacer() {
    return this.#narrowSpacer(1.781);
  }

  #sp4Surface() {
    const offset = 1.794;

    switch (this.#state.sp4) {
      case 'LAG020UR':
      case 'LAG024UR':
        return this.#wide2BurnerPond(offset);

      case 'LAG010UR':
      case 'LAG010CF':
      case 'LAE010CVA':
        return this.#oneBurnerPond(offset);

      case 'LAG010CK':
        return this.#flameGrillPond(offset);

      default:
        return this.#wideWorkstation(offset);
    }
  }

  #oneBurnerPond(offset) {
    const pond = this.#oneBurnerPondGeo.clone();
    pond.translate(offset, 0, 0);

    return pond;
  }

  #wide2BurnerPond(offset) {
    const pond = this.#twoBurnerPondWideGeo.clone();
    pond.translate(offset, 0, 0);

    return pond;
  }

  #narrow2BurnerPond(offset) {
    const pond = this.#twoBurnerPondNarrowGeo.clone();
    pond.translate(offset, 0, 0);

    return pond;
  }

  #wideWorkstation(offset) {
    const workstation = this.#workstationWideGeo.clone();
    workstation.translate(offset, 0, 0);

    return workstation;
  }

  #narrowWorkstation(offset) {
    const workstation = this.#workstationNarrowGeo.clone();
    workstation.translate(offset, 0, 0);

    return workstation;
  }

  #flameGrillPond(offset) {
    const pond = this.#flameGrillPondGeo.clone();
    pond.translate(offset, 0, 0);

    return pond;
  }

  #sully4BurnerPond(offset) {
    const pond = this.#sully4BurnerPondGeo.clone();
    pond.translate(offset, 0, 0);

    return pond;
  }

  #wideSpacer(offset) {
    const spacer = this.#spacerWideGeo.clone();
    spacer.translate(offset, 0, 0);

    return spacer;
  }

  #narrowSpacer(offset) {
    const spacer = this.#spacerNarrowGeo.clone();
    spacer.translate(offset, 0, 0);

    return spacer;
  }

  #makeTopRimGeometry(range) {
    this.#topRimMesh = range.getObjectByName('2200_Range_Top_Rim');

    this.#topRimGeo = this.#topRimMesh.geometry.clone();
    this.#topRimGeo.applyQuaternion(this.#topRimMesh.quaternion);
    this.#topRimGeo.translate(
      this.#topRimMesh.position.x,
      this.#topRimMesh.position.y,
      this.#topRimMesh.position.z
    );
  }

  #make1BurnerPondGeometry(optionBases) {
    const oneBurnerPond = optionBases.getObjectByName('1_Burner_Pond');

    this.#oneBurnerPondGeo = oneBurnerPond.geometry.clone();
    this.#oneBurnerPondGeo.applyQuaternion(oneBurnerPond.quaternion);
    this.#oneBurnerPondGeo.translate(
      oneBurnerPond.position.x - 0.702, // Align to inside left of range top rim
      oneBurnerPond.position.y,
      oneBurnerPond.position.z
    );
  }

  #makeWide2BurnerPondGeometry(optionBases) {
    const widePond = optionBases.getObjectByName('2_Burner_Pond_Wide');

    this.#twoBurnerPondWideGeo = widePond.geometry.clone();
    this.#twoBurnerPondWideGeo.applyQuaternion(widePond.quaternion);
    this.#twoBurnerPondWideGeo.translate(
      widePond.position.x - 1.391, // Align to inside left of range top rim
      widePond.position.y,
      widePond.position.z
    );
  }

  #makeNarrow2BurnerPondGeometry(optionBases) {
    const narrowPond = optionBases.getObjectByName('2_Burner_Pond_Narrow');

    this.#twoBurnerPondNarrowGeo = narrowPond.geometry.clone();
    this.#twoBurnerPondNarrowGeo.applyQuaternion(narrowPond.quaternion);
    this.#twoBurnerPondNarrowGeo.translate(
      narrowPond.position.x - 1.118, // Align to inside left of range top rim
      narrowPond.position.y,
      narrowPond.position.z
    );
  }

  #makeWideWorkstationGeometry(optionBases) {
    const wideStation = optionBases.getObjectByName('Workstation_Wide');

    this.#workstationWideGeo = wideStation.geometry.clone();
    this.#workstationWideGeo.applyQuaternion(wideStation.quaternion);
    this.#workstationWideGeo.translate(
      wideStation.position.x, // Already aligned to inside left of range top rim
      wideStation.position.y,
      wideStation.position.z
    );
  }

  #makeNarrowWorkstationGeometry(optionBases) {
    const narrowStation = optionBases.getObjectByName('Workstation_Narrow');

    this.#workstationNarrowGeo = narrowStation.geometry.clone();
    this.#workstationNarrowGeo.applyQuaternion(narrowStation.quaternion);
    this.#workstationNarrowGeo.translate(
      narrowStation.position.x - 0.416, // Align to inside left of range top rim
      narrowStation.position.y,
      narrowStation.position.z
    );
  }

  #makeFlameGrillPondGeometry(optionBases) {
    const flameGrillPond = optionBases.getObjectByName('Flame_Grill_Pond');

    this.#flameGrillPondGeo = flameGrillPond.geometry.clone();
    this.#flameGrillPondGeo.applyQuaternion(flameGrillPond.quaternion);
    this.#flameGrillPondGeo.translate(
      flameGrillPond.position.x - 2.353, // Align to inside left of range top rim
      flameGrillPond.position.y,
      flameGrillPond.position.z
    );
  }

  #makeSully4BurnerPondGeometry(optionBases) {
    const fourFeuxPond = optionBases.getObjectByName('Sully_4_Burner_Pond');

    this.#sully4BurnerPondGeo = fourFeuxPond.geometry.clone();
    this.#sully4BurnerPondGeo.applyQuaternion(fourFeuxPond.quaternion);
    this.#sully4BurnerPondGeo.translate(
      fourFeuxPond.position.x - 1.781, // Align to inside left of range top rim
      fourFeuxPond.position.y,
      fourFeuxPond.position.z
    );
  }

  #makeWideSpacerGeometry(optionBases) {
    const wideSpacer = optionBases.getObjectByName('26mm_Spacer');

    this.#spacerWideGeo = wideSpacer.geometry.clone();
    this.#spacerWideGeo.applyQuaternion(wideSpacer.quaternion);
    this.#spacerWideGeo.translate(
      wideSpacer.position.x - 1.092, // Align to inside left of range top rim
      wideSpacer.position.y,
      wideSpacer.position.z
    );
  }

  #makeNarrowSpacerGeometry(optionBases) {
    const narrowSpacer = optionBases.getObjectByName('13mm_Spacer');

    this.#spacerNarrowGeo = narrowSpacer.geometry.clone();
    this.#spacerNarrowGeo.applyQuaternion(narrowSpacer.quaternion);
    this.#spacerNarrowGeo.translate(
      narrowSpacer.position.x - 1.378, // Align to inside left of range top rim
      narrowSpacer.position.y,
      narrowSpacer.position.z
    );
  }
}

export { SSRangeTop };

import { Group } from 'three';

class KnobModels {
  classiqueKnob = new Group();
  moderneKnob = new Group();

  convectionOvenDial = new Group();
  convectionOvenSwitch = new Group();
  gasOvenDial = new Group();
  inductionDial = new Group();
  knobBurnerDial = new Group();
  multiCookerDial = new Group();
  planchaDial = new Group();
  warmingCupboardDial = new Group();

  #toggleSwitch = new Group();
  #leds = new Group();
  #amberLight = new Group();
  #redLight = new Group();

  #assets;
  #materials;

  constructor(assets, materials) {
    this.#assets = assets;
    this.#materials = materials;
    this.#loadModels();
    this.#createIndicatorLights();
    this.#applyMaterials();
    this.#addElectronicsToConvectionOvenDial();
    this.#addElectronicsToMultiCookerDial();
  }

  #loadModels() {
    // Classique Knob
    this.#assets.classiqueKnobData.scene.children.forEach((child) => {
      this.classiqueKnob.add(child.clone());
    });

    // Moderne Knob
    this.#assets.moderneKnobData.scene.children.forEach((child) => {
      this.moderneKnob.add(child.clone());
    });

    // Knob Dials
    this.#assets.knobDialsData.scene.children.forEach((child) => {
      switch (child.name) {
        case 'Dial_2IN': // Induction Ring Dial
          this.inductionDial.add(child.clone());
          break;
        case 'Dial_All_K': // Gas Burner Dial
          this.knobBurnerDial.add(child.clone());
          break;
        case 'Dial_EO': // Electric Oven Dial
          this.convectionOvenDial.add(child.clone());
          break;
        case 'Dial_EP': // Electric Plancha Dial
          this.planchaDial.add(child.clone());
          break;
        case 'Dial_GO': // Gas Oven Dial
          this.gasOvenDial.add(child.clone());
          break;
        case 'Dial_HC': // Warming Cupboard Dial
          this.warmingCupboardDial.add(child.clone());
          break;
        case 'Dial_MC': // Multi-Cooker Dial
          this.multiCookerDial.add(child.clone());
          break;
      }
    });

    // Oven Switch
    this.#assets.ovenSwitchData.scene.children.forEach((child) => {
      switch (child.name) {
        case 'TS': // Toggle Switch
          this.#toggleSwitch.add(child.clone());
          break;
        case 'TS_Bezels': // Toggle Switch Bezel
          this.#toggleSwitch.add(child.clone());
          break;
        case 'Dial_Light': // Indicator Light
          this.#leds.add(child.clone());
          break;
        case 'Dial_Light_Bezels': // Indicator Light Bezel
          this.#leds.add(child.clone());
          break;
      }
    });
  }

  #createIndicatorLights() {
    const amberLED = this.#leds.getObjectByName('Dial_Light').clone();
    amberLED.name = 'led';
    const amberBezel = this.#leds.getObjectByName('Dial_Light_Bezels').clone();
    amberBezel.name = 'bezel';
    this.#amberLight.add(amberLED, amberBezel);

    const redLED = amberLED.clone();
    const redBezel = amberBezel.clone();
    this.#redLight.add(redLED, redBezel);
  }

  #applyMaterials() {
    this.#materials.applyBrassTexture(
      this.classiqueKnob.getObjectByName('C_LO2_knob_3'),
      this.#amberLight.getObjectByName('bezel'),
      this.#redLight.getObjectByName('bezel')
    );

    this.#materials.applyChromeTexture(
      this.moderneKnob.getObjectByName('M_LO2_knob_3'),
      this.#toggleSwitch.getObjectByName('TS'),
      this.#toggleSwitch.getObjectByName('TS_Bezels')
    );

    this.#materials.applyAmberLightTexture(
      this.#amberLight.getObjectByName('led')
    );

    this.#materials.applyRedLightTexture(this.#redLight.getObjectByName('led'));
  }

  #addElectronicsToConvectionOvenDial() {
    const amberLight = this.#amberLight.clone();
    const redLight = this.#redLight.clone();
    redLight.position.y += -0.02;
    const toggleSwitch = this.#toggleSwitch.clone();

    this.convectionOvenDial.add(amberLight, redLight, toggleSwitch);
  }

  #addElectronicsToMultiCookerDial() {
    const amberLight = this.#amberLight.clone();
    amberLight.position.x += 0.01;
    amberLight.position.y += 0.005;
    const redLight = this.#redLight.clone();
    redLight.position.x += -0.163;
    redLight.position.y += 0.018;
    const toggleSwitch = this.#toggleSwitch.clone();
    toggleSwitch.position.x += -0.165;
    toggleSwitch.position.y += 0.039;

    this.multiCookerDial.add(amberLight, redLight, toggleSwitch);
  }
}

export { KnobModels };

import { Group } from 'three';
import { buildBurners } from './burners.js';

const BACK_BURNER_OFFSET = -0.28;

class RangeTopOptions {
  flameGrill = new Group();
  inductionRings = new Group();
  multiCooker = new Group();
  plancha = new Group();
  tradPlateGroup = new Group();
  optionBases = new Group();

  fiveAnd11KBurners = new Group();
  fiveAnd15KBurners = new Group();
  elevenKBurners = new Group();
  fifteenKBurners = new Group();
  eighteenKBurner = new Group();

  #eighteenKBurnerGrate;
  #twoBurnerGrate;
  #traditionalPlate;
  #fiveKBurner;
  #elevenKBurner;
  #fifteenKBurner;
  #eighteenKBurner;

  #assets;
  #materials;

  constructor(assets, materials) {
    this.#assets = assets;
    this.#materials = materials;
    this.#loadModels();
    this.#applyTextures();
    this.#assemble5and11KBurners();
    this.#assemble5and15KBurners();
    this.#assemble11KBurners();
    this.#assemble15KBurners();
    this.#assemble18KBurner();
    this.#assembleTradPlate();
  }

  #loadModels() {
    // Flame Grill
    this.#assets.flameGrillData.scene.children.forEach((child) => {
      this.flameGrill.add(child.clone());
    });

    // Induction Rings
    this.#assets.inductionRingsData.scene.children.forEach((child) => {
      this.inductionRings.add(child.clone());
    });

    // Multi-Cooker
    this.multiCooker = this.#assets.multiCookerData.scene.children[0];

    // Plancha
    this.#assets.planchaData.scene.children.forEach((child) => {
      this.plancha.add(child.clone());
    });

    // 18k Burner Grate
    this.#eighteenKBurnerGrate =
      this.#assets.eighteenKBurnerGrateData.scene.children[0];

    // 2 Burner Grate
    this.#twoBurnerGrate = this.#assets.twoBurnerGrateData.scene.children[0];

    // Traditional (Simmer) Plate
    this.#traditionalPlate =
      this.#assets.traditionalPlateData.scene.children[0];

    // 5k, 11k, 15k, 18k Burners
    [
      this.#fiveKBurner,
      this.#elevenKBurner,
      this.#fifteenKBurner,
      this.#eighteenKBurner,
    ] = buildBurners(this.#assets.allBurnersData.scene, this.#materials);

    // Range Top Option Bases
    this.#assets.rangetopPiecesData.scene.children.forEach((child) => {
      this.optionBases.add(child.clone());
    });
  }

  #applyTextures() {
    this.#applyFlameGrillTextures(this.flameGrill);
    this.#applyInductionRingsTexture(this.inductionRings);
    this.#applyPlanchaTextures(this.plancha);

    this.#materials.applyStainlessSteelTexture(this.multiCooker);

    this.#materials.applyEnamelCastIronTexture(
      this.#eighteenKBurnerGrate,
      this.#twoBurnerGrate,
      this.#traditionalPlate
    );
  }

  #applyFlameGrillTextures(flameGrill) {
    this.#materials.applyStainlessSteelTexture(
      flameGrill.getObjectByName('RO2_GG'), // flame grill cooking surface
      flameGrill.getObjectByName('RO2_GG001') // flame grill briquet holder
    );

    this.#materials.applyBrassTexture(
      flameGrill.getObjectByName('RO2_Handel_GG'), // flame grill rear posts
      flameGrill.getObjectByName('RO2_Handel_GG001') // flame grill handles
    );

    // TODO: Find a better material for this
    this.#materials.applyEnamelCastIronTexture(
      flameGrill.getObjectByName('RO2_GG002') // flame grill briquets
    );
  }

  #applyInductionRingsTexture(inductionRings) {
    this.#materials.applyMatteBlackTexture(
      inductionRings.getObjectByName('RO2_2IR_P') // induction burner surface
    );
  }

  #applyPlanchaTextures(plancha) {
    this.#materials.applyStainlessSteelTexture(
      plancha.getObjectByName('RO2_EP_T'), // plancha base
      plancha.getObjectByName('RO2_EP_O_T') // plancha cover
    );

    this.#materials.applyBrushedSSTexture(
      plancha.getObjectByName('RO2_EP_O_Ring_T') // plancha handle bezel
    );
  }

  #assemble5and11KBurners() {
    const twoBurnerGrate = this.#twoBurnerGrate.clone();
    const front11KBurner = this.#elevenKBurner.clone();
    const back5KBurner = this.#fiveKBurner.clone();
    back5KBurner.position.z += BACK_BURNER_OFFSET;

    this.fiveAnd11KBurners.add(twoBurnerGrate, front11KBurner, back5KBurner);
  }

  #assemble5and15KBurners() {
    const twoBurnerGrate = this.#twoBurnerGrate.clone();
    const front15KBurner = this.#fifteenKBurner.clone();
    const back5KBurner = this.#fiveKBurner.clone();
    back5KBurner.position.z += BACK_BURNER_OFFSET;

    this.fiveAnd15KBurners.add(twoBurnerGrate, front15KBurner, back5KBurner);
  }

  #assemble11KBurners() {
    const twoBurnerGrate = this.#twoBurnerGrate.clone();
    const front11KBurner = this.#elevenKBurner.clone();
    const back11KBurner = front11KBurner.clone();
    back11KBurner.position.z += BACK_BURNER_OFFSET;

    this.elevenKBurners.add(twoBurnerGrate, front11KBurner, back11KBurner);
  }

  #assemble15KBurners() {
    const twoBurnerGrate = this.#twoBurnerGrate.clone();
    const front15KBurner = this.#fifteenKBurner.clone();
    const back15KBurner = front15KBurner.clone();
    back15KBurner.position.z += BACK_BURNER_OFFSET;

    this.fifteenKBurners.add(twoBurnerGrate, front15KBurner, back15KBurner);
  }

  #assemble18KBurner() {
    const eighteenKBurnerGrate = this.#eighteenKBurnerGrate.clone();
    const center18KBurner = this.#eighteenKBurner.clone();

    this.eighteenKBurner.add(eighteenKBurnerGrate, center18KBurner);
  }

  #assembleTradPlate() {
    const traditionalPlate = this.#traditionalPlate.clone();
    traditionalPlate.position.x += -1.09;
    const center18KBurner = this.#eighteenKBurner.clone();

    this.tradPlateGroup.add(traditionalPlate, center18KBurner);
  }
}

export { RangeTopOptions };
